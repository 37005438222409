import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    ruletree:[],
    fielddata:{
        title:{type:'input',title:'角色名称',name:'title',autoFocus:true,placeholder:"请输入角色名称",rules:[{
            required: true,
            message: '名称不能为空'
        }]}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启动'},
            {value:0,name:'禁用'}
        ]}
    },
    page:1,
    pageSize:Conf.page_Conf.pageSize
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.RULETREE:
            return state.merge({
                ruletree : fromJS(action.ruletree)
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}