import * as constants from "./constants";

export const openSubMenu=(value)=>{
    return {
        type:constants.OPEN_SUBMENU
        ,value
    }
};

const getMenuData=(navmenu,rootSubmenuKeys)=>{
    return {
        type:constants.GET_MENU
        ,navmenu
        ,rootSubmenuKeys
    }
};

export const getMenuInfo=(result=[])=>{
    return async (dispatch)=>{
        let rootSubmenuKeys=result.map((item,index)=>{
            if(item.children===undefined || item.children===[] || item.children==null || item.children===""){
                return "";
            }else{
                return String(item.key);
            }
        })
        rootSubmenuKeys=rootSubmenuKeys.filter(
            item=>item!==""
        );
        dispatch(getMenuData(result,rootSubmenuKeys));
    }
}
