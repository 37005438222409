import * as constants from './constants';

export const iconToggle=(value)=>{
    return {
        type:constants.ICON_TOGGLE
        ,value
    }
};
export const bindTitle=(value)=>{
    return {
        type:constants.HOME_TITLE
        ,value
    }
};