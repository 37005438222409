import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    fielddata:{
        arr:[
            'pid','name'
			// ,'dirname'
			,'color'
			,'thumb'
			,'description_en'
            ,'seo_title','keywords','description'
            // ,'seo_title_en','keywords_en','description_en'
			,'template','con_tpl'
            ,'status','sort'
		],
        pid:{type:'select.tree',title:'上级栏目',name:'pid',placeholder:"请选择(默认顶级)",treeData:[]}
        ,name:{type:'input',title:'栏目名称',name:'name',autoFocus:true,placeholder:"请输入栏目名称",rules:[{
            required: true,
            message: '栏目名不能为空'
        }]}
        ,description_en:{type:'text',title:'描述',name:'description_en',rows:4,placeholder:"请输入描述"}
        ,dirname:{type:'input',title:'英文名',name:'dirname',placeholder:"请输入英文名"}
        ,thumb:{type:'upload',title:'栏目图',name:'thumb',length:1,data:{path:'news',size:6}}
		,color:{type:'input',title:'背景色',name:'color',placeholder:"banner背景色"}

        ,seo_title:{type:'input',title:'SEO标题',name:'seo_title',placeholder:"请输入SEO标题"}
        ,keywords:{type:'input',title:'SEO关键字',name:'keywords',placeholder:"输入SEO关键字"}
        ,description:{type:'text',title:'SEO简介',name:'description',rows:4,placeholder:"请输入SEO简介"}
        
		,seo_title_en:{type:'input',title:'SEO标题(英文)',name:'seo_title_en',placeholder:"请输入SEO标题(英文)"}
        ,keywords_en:{type:'input',title:'关键字(英文)',name:'keywords_en',placeholder:"输入关键字(英文)"}
        //,description_en:{type:'text',title:'描述(英文)',name:'description_en',rows:4,placeholder:"请输入描述(英文)"}
        
		,template:{type:'input',title:'模板文件',name:'template',placeholder:"请输入模板文件名,栏目默认为catgory"}
        ,con_tpl:{type:'input',title:'内容页模板',name:'con_tpl',placeholder:"内容页模板默认:content"}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启动'},
            {value:0,name:'禁用'}
        ]}
        ,sort:{type:'input.number',title:'排序',name:'sort',min:0,placeholder:"0"}
    },
    catetree:[]
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.CATETREE:
            return state.merge({
                catetree : fromJS(action.catetree)
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
        default:
    }
    return state;
}