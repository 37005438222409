import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    btnloading:false,
    page:1,
    total:0,
    pageSize:Conf.page_Conf.pageSize,
    emptyText:'暂无数据',
    fielddata:{
        arr:['name','nickname']
        ,name:{type:'input',title:'用户名',name:'name',autoFocus:true,placeholder:"请输入用户名称",rules:[{
            required: true,
            message: '用户名不能为空'
        }]}
        ,nickname:{type:'input',title:'昵称',name:'nickname',placeholder:"请输入昵称名称"}
        ,head_img:{type:'upload',title:'头像',name:'head_img',length:1,data:{path:'avater',size:6}}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启动'},
            {value:0,name:'禁用'}
        ]}
        ,remark:{type:'text',title:'备注',name:'remark',rows:4}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        case constants.BTNLOADING:
            return state.merge({
                btnloading : action.bool
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
        default:
    }
    return state;
}