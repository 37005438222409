import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:["title",'name','description','type','options','required']
        ,title:{type:'input',title:'字段名称',name:'title',autoFocus:true,placeholder:"请输入字段名称"}
        ,name:{type:'input',title:'字段名称',name:'name',autoFocus:true,placeholder:"请输入字段name",rules:[{
            required: true,
            whitespace: true,
            message: '字段name不能为空'
        }]}
        ,description:{type:'text',title:'提示',rows:2,name:'description'}
        ,type:{type:'radio',title:'类型',name:'type',options:[
            {value:1,name:'单行文本'},
            {value:2,name:'多行文本'},
            {value:3,name:'单选'},
            {value:4,name:'多选'},
        ]}
        ,options:{type:'text',title:'提示',rows:4,name:'options',placeholder:"如果为单选或者多选需要填写选项,格式为(选项名称|选项值)"}
        ,required:{type:'radio.button',title:'是否必填',name:'required',options:[
            {value:1,name:'非必填'},
            {value:2,name:'必填'},
        ]}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}