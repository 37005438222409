import * as constants from './constants';

export const getData=(data)=>{
    return {
        type:constants.DATA
        ,data
    }
};
export const setLoading=(loading)=>{
    return {
        type:constants.LOADING
        ,loading
    }
};