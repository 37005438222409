import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    loading:true,
    formData:{
        arr:[
			'title'
			,'keywords'
			,'description'
			// ,'title_en'
			// ,'keywords_en'
			// ,'description_en'
			,'template'
			,'adderss'
			,'school_adderss'
			,'tel'
			,'qrcode'
			,'paper_id'
		]
        ,title:{type:'input',title:'站点名称',name:'title',autoFocus:true,placeholder:"请输入站点名称",rules:[{
            required: true,
            whitespace: true,
            message: '站点名称不能为空'
        }]}
		,title_en:{type:'input',title:'站点英文名称',name:'title_en',placeholder:"请输入站点英文名称"}
        ,keywords:{type:'input',title:'关键字',name:'keywords'}
        ,keywords_en:{type:'input',title:'英文关键字',name:'keywords_en'}
        ,description:{type:'text',title:'站点描述Seo',rows:4,name:'description'}
        ,description_en:{type:'text',title:'英文站点描述Seo',rows:4,name:'description_en'}
        ,template:{type:'input.hidden',title:'使用模板',name:'template',rules:[{
            required: true,
            whitespace: true,
            message: '模板不能为空'
        }]}

		
        // ,address:{type:'text',title:'国内办事处地址',rows:3,name:'address'}
        ,school_address:{type:'text',title:'学校地址',rows:3,name:'school_address'}
        ,tel:{type:'text',title:'咨询热线',rows:3,name:'tel'}
        ,qrcode:{type:'upload',title:'学校二维码',name:'qrcode',length:1,data:{path:'qrcode',size:1}}
		,paper_id:{
			type:'select',title:'当前测试试题',name:'paper_id',
			options:[],
			value: '',
			rules:[{
				required: true,
				message: '在线测试试题'
			}]
		}
    },
    data:{}
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.DATA:
            return state.merge({
                data : fromJS(action.data),
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        default:
            break;
    }
    return state;
}