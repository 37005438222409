import { Modal } from 'antd';

/**
 * 消息提示
 * 
 * @params {
 * type:   success|error|info|warning|warn|open|close|confirm
 * 
 * 如果使用close,请传入对象{key:string}
 * }
 * 
 */
export default function(content="成功", type='confirm',obj={}){
    let defaultOption={
        title:'消息提示',
        centered:true,
        className:'luck-modal'
    }
    obj.content = content;

    let option=Object.assign(defaultOption,obj);
    if(type === 'confirm'){
        Modal[type](option);
    }else{
        Modal[type](option);
    }
};