import { notification } from 'antd';

/**
 * 消息提示
 * 
 * @params {
 * type:   success|error|info|warning|warn|open|close|destroy
 * 
 * 如果使用close,请传入对象{key:string}
 * }
 * 
 */
export default function(content="成功", type='info', obj={}){
    let defaultOption={
        placement: 'topRight',
        top: 50,
        duration: 3,
        message:'消息提示'
    }
    obj.message = obj.title ? obj.title   :   obj.message ? obj.message:defaultOption.message;
    obj.description=content;
    let option=Object.assign(defaultOption,obj);
    if(type === 'close'){
        notification[type](content);
    }else if(content === 'destroy'){
        notification['destroy']();
    }else if(type === 'destroy'){
        notification[type]();
    }else{
        notification[type](option);
    }
};