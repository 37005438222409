import { combineReducers } from 'redux-immutable';
import { reducer as mainReducer } from './default/main';
import { reducer as headerReducer } from './default/header';
import { reducer as navLeftReducer } from './default/navleft';
import { reducer as managerReducer } from './default/manager';
import { reducer as ruleReducer } from './default/rule';
import { reducer as roleReducer } from './default/role';

//cms
import { reducer as cmsCateReducer } from './cms/category';
import { reducer as cmsNewReducer } from './cms/article';
import { reducer as cmsNewEnReducer } from './cms/article_en';
import { reducer as cmsTagsReducer } from './cms/tags';
import { reducer as cmsAdsReducer } from './cms/ads';
import { reducer as cmsAdsListReducer } from './cms/ads_list';
import { reducer as cmsCollectionReducer } from './cms/collection';
import { reducer as cmsPositionReducer } from './cms/position';
import { reducer as cmsPositionListReducer } from './cms/position_list';
import { reducer as cmsMenuReducer } from './cms/menu';
import { reducer as cmsSettingReducer } from './cms/setting';
import { reducer as cmsFormReducer } from './cms/form';
import { reducer as cmsFormFieldReducer } from './cms/form_field';
import { reducer as cmsFormListReducer } from './cms/form_list';
import { reducer as cmsLinkReducer } from './cms/link';
import { reducer as cmsCountryReducer } from './cms/country';
import { reducer as cmsKeywordReducer } from './cms/keyword';

//exam
import { reducer as examOrganizationReducer } from './exam/organization';
import { reducer as examOrganizationListReducer } from './exam/organization_list';
import { reducer as examMajorReducer } from './exam/major';
import { reducer as examQuestionReducer } from './exam/question';
import { reducer as examPaperReducer } from './exam/paper';
import { reducer as examUserReducer } from './exam/user';
import { reducer as examUserExamReducer } from './exam/userexam';
import { reducer as examExamInfoReducer } from './exam/examinfo';
import { reducer as examTestInfoReducer } from './exam/testinfo';
import { reducer as examSettingReducer } from './exam/setting';
import { reducer as examTrainingReducer } from './exam/training_list';

const reducer = combineReducers({
    main: mainReducer
    ,header: headerReducer
    ,navleft: navLeftReducer
    ,manager: managerReducer
    ,rule: ruleReducer
    ,role: roleReducer

    ,cms_cate:cmsCateReducer
    ,cms_new:cmsNewReducer
	,cms_new_en:cmsNewEnReducer
    ,cms_tags:cmsTagsReducer
    ,cms_ads:cmsAdsReducer
    ,cms_ads_list:cmsAdsListReducer
	,cms_collection:cmsCollectionReducer
	,cms_position:cmsPositionReducer
    ,cms_position_list:cmsPositionListReducer
	,cms_menu:cmsMenuReducer
    ,cms_setting:cmsSettingReducer
    ,cms_form:cmsFormReducer
    ,cms_form_field:cmsFormFieldReducer
    ,cms_form_list:cmsFormListReducer
    ,cms_link:cmsLinkReducer
	,cms_keyword:cmsKeywordReducer
	//exam
	,exam_organization:examOrganizationReducer
	,exam_organization_list:examOrganizationListReducer
	,exam_major:examMajorReducer
	,exam_question:examQuestionReducer
	,exam_paper:examPaperReducer
	,exam_user:examUserReducer
	,exam_userexam:examUserExamReducer
	,exam_examinfo:examExamInfoReducer
	,exam_testinfo:examTestInfoReducer
	,exam_setting:examSettingReducer
	,exam_training:examTrainingReducer
	,cms_country:cmsCountryReducer
});

export default reducer;