import { Modal } from 'antd';
import { Conf } from '../../utils';

/**
 * 弹出框
 * 
 * @params {title:string,className:string,okText:string,type:string,content:string}
 * 
 */
export default function(content="",type="success",obj={}){
    let alert_Conf=Conf.alert_Conf;
    obj.type=type;
    obj.content=content;
    let modalConf=Object.assign(alert_Conf,obj);
    if(modalConf.onOk === undefined){
        /*modalConf.onOk=function(e){
            e();
        };*/
    }
    switch(modalConf.type.toLowerCase()){
        case "error":
            Modal.error(
                {
                    ...modalConf
                }
            );
            break;
            
        case "success":
            Modal.success(
                {
                    ...modalConf
                }
            );
            break;
        case "warning":
            Modal.warning(
                {
                    ...modalConf
                }
            );
            break;
        default:
            Modal.info(
                {
                    ...modalConf
                }
            );
            break;
    }
}