import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:[
            'name',
            'status',
            'address',
            'description'
        ]
        ,name:{type:'input',title:'培训所在地',name:'name',autoFocus:true,placeholder:"请输入培训所在地名称",rules:[{
            required: true,
            whitespace: true,
            message: '培训所在地名称不能为空'
        }]}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启用'},
            {value:2,name:'禁用'}
        ]}
        ,end_time:{type:'input.time',title:'过期时间',name:'end_time',line:true}
        ,address:{type:'text',title:'培训地点',rows:4,name:'address'}
        ,description:{type:'text',title:'备注',rows:4,name:'description'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}