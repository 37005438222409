import * as constants from './constants';

export const getList=(list,total)=>{
    return {
        type:constants.LIST
        ,list
        ,total
    }
};
export const setLoading=(loading)=>{
    return {
        type:constants.LOADING
        ,loading
    }
};

export const setPagesize=(pageSize)=>{
    return {
        type:constants.PAGESIZE
        ,pageSize
    }
};
export const setPage=(page)=>{
    return {
        type:constants.PAGE
        ,page
    }
};
export const setBtnLoading=(bool)=>{
    return {
        type:constants.BTNLOADING
        ,bool
    }
}

export const setEmptyText=(emptyText)=>{
    return {
        type:constants.NOAUTH
        ,emptyText
    }
}