// export const fileUrl = "http://www.gid.cc";
// export const ajaxUrl = "http://admin-api.gid.cc/";
export const fileUrl = "http://cn.nanyang.edu.sg/";
export const ajaxUrl = "http://cn.nanyang.edu.sg/adminapi/";
export const ADMIN_TITLE = "南洋管理学院";
export const ADMIN_LOGO = "http://cn.nanyang.edu.sg/static/pc/images/logo.png";

export const Path = "";


export const nav_Conf={
    trigger:null
    ,collapsible:true   //是否能收起
    ,theme:"dark"
    ,collapsedWidth:64
    ,className:"layout-sider"
    ,style:{}

    //menu菜单
    ,mode:"inline"
}

export const alert_Conf={
    title:'提示',
    className:"luck-modal",
    okText:"确定",
    type:'success',
    content:'成功'
}

//表格配置
export const page_Conf={
    pageSize:10,    //每页条数
    size:"",        //为small为迷你版本
    showTotal:(total, range) =>`当前显示 ${range[0]}-${range[1]} 总共 ${total} 数据`,
    showSizeChanger:true,
    pageSizeOptions:['10','20','50','100'],
    showQuickJumper:false,
    hideOnSinglePage:false,
};

export const scroll_Conf={ x:'max-content',y:600};
export const table_Conf={
    bordered:true,      //表格是否需要边框
    loading: false,     //表格loading
    title: undefined,   //是否有抬头
    size:'middle',
    rowKey:"id"
};
//抽屉
export const drawerConf={
    title:"",               //抽屉标题
    width:"720px",          //抽屉宽度
    maskClosable:false,     //允许关闭
    destroyOnClose:false,   //关闭销毁里面内容
    closable:false,         //关闭按钮
    visible:false,          //是否可见
    placement:'right',      //抽屉方向
    style:{
        overflow: 'auto',
        paddingBottom: 40
    }
};

export const choseItem = {
	'0':'A',
	'1':'B',
	'2':'C',
	'3':'D',
	'4':'E',
	'5':'F',
	'6':'G',
	'7':'H',
	'8':'I',
	'9':'J',
	'10':'K',
	'11':'L',
}
