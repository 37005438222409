import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    btnloading:false,
    page:1,
    total:0,
    pageSize:Conf.page_Conf.pageSize,
    emptyText:'暂无数据',
    fielddata:{
		arr:['name','card_id',
		//'password',
		'address',
		'company',
		'status']
        ,name:{type:'input',title:'学员名',name:'name',disabled:true,placeholder:"请输入学员名",rules:[{
            required: true,
            message: '学员名不能为空'
        }]}
        ,card_id:{type:'input',title:'身份证',name:'card_id',placeholder:"请输入身份证",rules:[{
            required: true,
            message: '身份证不能为空'
        }]}
        ,address:{type:'text',title:'详细地址',name:'address',placeholder:"请输入详细地址",rows:4,rules:[{
            required: true,
            message: '详细地址不能为空'
        }]}
        ,company:{type:'input',title:'工作单位',name:'company',placeholder:"请输入工作单位",rules:[{
            required: true,
            message: '工作单位不能为空'
        }]}
        ,password:{type:'input.pass',title:'密码',name:'password',placeholder:"请输入密码"}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启动'},
            {value:0,name:'禁用'}
        ]}
        ,remark:{type:'text',title:'备注',name:'remark',rows:4}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        case constants.BTNLOADING:
            return state.merge({
                btnloading : action.bool
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
        default:
    }
    return state;
}