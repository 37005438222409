import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:[
			'name',
			'catid',
			'maxnum',
			'sort',
        ]
		,name:{type:"input",title:'推荐位名称',name:'name',rules:[{
            required: true,
            message: '推荐位名称不能为空'
        }]}
		,catid:{type:'select.treeopen',title:'所属栏目',name:'catid',placeholder:"请选择(默认全部栏目)",treeData:[]}
		,maxnum:{type:'input.number',title:'最大保存条数',name:'maxnum',value:20}
		,sort:{type:'input.number',title:'排序',name:'sort'}
    }
    ,catetree:[]
});
export default (state=defaultState,action)=>{
    switch (action.type) {
		case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        case constants.CATETREE:
            return state.merge({
                catetree : fromJS(action.catetree)
            })
        default:
            break;
    }
    return state;
}