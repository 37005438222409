import * as constants from './constants';

export const getList=(list,total)=>{
    return {
        type:constants.LIST
        ,list
        ,total
    }
};
export const setLoading=(loading)=>{
    return {
        type:constants.LOADING
        ,loading
    }
};
export const setEmptyText=(emptyText)=>{
    return {
        type:constants.NOAUTH
        ,emptyText
    }
};
export const setTreeData=(menutree,catetree)=>{
    return {
        type:constants.MENUTREE
        ,menutree
        ,catetree
    }
};