import main from "./main"
import exam from "./exam"
export default {
    //菜单页面
    menupage:[
        ...main
		,...exam
    ],
    //隐藏打开的页面
    hidepage:[
        //添加文章
        {
            url:'/cms/article/add/:cid?',
            name:'CMSArticleAdd',
            Com:import('../cms/article/add')
        },
        {
            url:'/cms/article/edit/:id',
            name:'CMSArticleEdit',
            Com:import('../cms/article/edit')
        },
        {
            url:'/cms/article/editen/:id',
            name:'CMSArticleEditEn',
            Com:import('../cms/article/editEn')
        },
        //百度编辑器单图上传
        /*{
            url:'/ueditor/simpleupload/:state/:date/:name',
            name:'UeditorSimpleupload',
            Com:import('../nomatch/ueditorSimpleUpload.js')
        },*/
    ]
}