export default [
    //栏目管理
    {
        url:'/cms/category',
        name:'CMSCategory',
        Com:import('../../cms/category')
    }
    //文章管理
    ,{
        url:'/cms/article',
        name:'CMSArticle',
        Com:import('../../cms/article')
    }
    //标签管理
    ,{
        url:'/cms/tags',
        name:'CMSTags',
        Com:import('../../cms/tags')
    }
    //广告位管理
    ,{
        url:'/cms/ads',
        name:'CMSAds',
        Com:import('../../cms/ads')
    }
    //广告管理
    ,{
        url:'/cms/ads/list',
        name:'CMSAdsList',
        Com:import('../../cms/ads/list')
    }
    //采集管理
    ,{
        url:'/cms/collection',
        name:'CMSCollection',
        Com:import('../../cms/collection')
    }
    //推荐位
    ,{
        url:"/cms/position",
        name:'CMSPosition',
        Com:import('../../cms/position')
    }
    //推荐文章管理
    ,{
        url:'/cms/position/list',
        name:'CMSPositionList',
        Com:import('../../cms/position/list')
    }
    ,{
        url:'/cms/menu',
        name:'CMSMenu',
        Com:import('../../cms/menu')
    }
    //系统设置
    ,{
        url:'/cms/setting',
        name:'CMSSetting',
        Com:import('../../cms/setting')
    }
    
    //表单管理
    ,{
        url:'/cms/form',
        name:'CMSForm',
        Com:import('../../cms/form')
    }
    //表单字段管理
    ,{
        url:'/cms/form/set',
        name:'CMSFormSet',
        Com:import('../../cms/form/set')
    }
    //数据管理
    ,{
        url:'/cms/form/list',
        name:'CMSFormList',
        Com:import('../../cms/form/list')
    }
    //友情链接
    ,{
        url:'/cms/link',
        name:'CMSLink',
        Com:import('../../cms/link')
    }
    ,{
        url:'/info',
        name:'Info',
        Com:import('../../nomatch/info')
    }
    //关键词
    ,{
        url:'/cms/keyword',
        name:'CMSKeyword',
        Com:import('../../cms/keyword')
    }
    //国家
    ,{
        url:'/cms/country',
        name:'CMSCountry',
        Com:import('../../cms/country')
    }
]