import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    fielddata:{
        pid:{type:'select.tree',title:'上级规则',name:'pid',placeholder:"请选择(默认顶级)",treeData:[]}
        ,title:{type:'input',title:'名称',name:'title',autoFocus:true,placeholder:"请输入名称",rules:[{
            required: true,
            message: '名称不能为空'
        }]}
        ,name:{type:'input',title:'规则',name:'name',placeholder:"请输入规则"}
        ,url:{type:'input',title:'菜单地址',name:'url',placeholder:"请输入菜单地址"}
        ,icon:{type:'select.icon',title:'图标',name:'icon',placeholder:"请选择图标"}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启动'},
            {value:0,name:'禁用'}
        ]}
        ,showed:{type:'radio',title:'后台菜单',name:'showed',options:[
            {value:1,name:'显示'},
            {value:0,name:'隐藏'}
        ]}
        ,sort:{type:'input.number',title:'排序',name:'sort',min:0,placeholder:"0"}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
        default:
    }
    return state;
}