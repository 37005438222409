import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:[
			'address'
			,'start_time'
			,'term'
			,'num'
			,'name'
        ]
        ,address:{type:'input',title:'培训地点',name:'address',autoFocus:true,placeholder:"请输入培训地点",rules:[{
            required: true,
            whitespace: true,
            message: '培训点不能为空'
		}]}
        ,start_time:{type:'input.month',title:'培训时间',name:'start_time',placeholder:""}
        ,term:{type:'input.number',title:'学期',name:'term',min:0}
        ,num:{type:'input.number',title:'人数',name:'num',min:0}
        ,name:{type:'input',title:'培训老师',name:'name',min:0}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}