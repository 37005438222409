import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:['name','tag','description']
        ,name:{type:'input',title:'专业名称',name:'name',autoFocus:true,placeholder:"请输入专业名称",rules:[{
            required: true,
            whitespace: true,
            message: '专业名称不能为空'
        }]}
        ,description:{type:'text',title:'备注',rows:4,name:'description'}
        ,tag:{type:'input',title:'标签',name:'tag',autoFocus:true,placeholder:"请输入自己识别的标签用来生成题库的时候选择",rules:[{
            required: true,
            whitespace: true,
            message: '标签不能为空'
        }]}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}