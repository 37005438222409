import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    btnloading:false,
    treeData:[],
    userInfo:{},
    statusValue:0
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.BTNLOADING:
            return state.merge({
                btnloading : action.bool
            })
        case constants.TREEDATA:
            return state.merge({
                treeData : fromJS(action.treeData)
            })
        case constants.GET_USERINFO:
            return state.merge({
                userInfo : action.userInfo
            })
            
        default:
    }
    return state;
}