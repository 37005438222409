import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    openKeys:[]
    ,rootSubmenuKeys:[]
    ,navmenu:[]
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.OPEN_SUBMENU:
            return state.set('openKeys',fromJS(action.value));
        case constants.GET_MENU:
            return state.merge({
                navmenu:fromJS(action.navmenu),
                rootSubmenuKeys:fromJS(action.rootSubmenuKeys)
            });
        default:
            break;
    }
    return state;
}