import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    cates:[],
    defcateid:[],
    posts:[],
    defpostid:[],
    news:{},
    formLeft:{
		arr:[
            'cid'
			,'title'
			,'title_en'
			,'keywords_en'
			,'description_en'
			,'content_en'
			,'images_en'
		],
        cid:{type:'select.treeopen',title:'所属栏目',name:'cid',placeholder:"请选择栏目",treeData:[],rules:[{
            required: true,
            message: '栏目不能为空'
        }]}
        ,title:{type:'input.hidden',title:'标题',name:'title',placeholder:"请输入标题"}
        ,title_en:{type:'input',title:'标题(英文)',name:'title_en',autoFocus:true,placeholder:"请输入英文标题"}
        ,keywords_en:{type:'input',title:'关键字(英文)',name:'keywords_en'}
        ,description_en:{type:'text',title:'描述(英文)',name:'description_en',rows:4}
        ,content_en:{type:'text.ueditor',title:'内容(英文)',name:'content_en',config:{
                height:500
            },path:'news'
            ,rules:[{
                required: true,
                whitespace: true,
                message: '英文内容不能为空'
            }]
        }
        ,images_en:{type:'upload',title:'图片组(英文)',name:'images_en',length:20,data:{path:'news',size:6}}
		
    }
    ,formRight:{
        arr:[
			'thumb_en'
			,'building_en'
			,'location_en'
			,'material_en'
			,'surface_en'
			,'completiontime_en'
		]
        ,thumb_en:{type:'upload',title:'缩略图(英文)',name:'thumb_en',length:1,data:{path:'news',size:6}}
		
        ,building_en:{type:'input',title:'建筑类型(英文)',name:'building_en'}
        ,location_en:{type:'input',title:'地址(英文)',name:'location_en'}
        ,material_en:{type:'input',title:'铝材料(英文)',name:'material_en'}
        ,surface_en:{type:'input',title:'颜色(英文)',name:'surface_en'}
        ,completiontime_en:{type:'input',title:'修建时间(英文)',name:'completiontime_en'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        case constants.CATE:
            return state.merge({
                cates : fromJS(action.cates),
                defcateid : fromJS(action.defcateid)
            })
        case constants.POST:
            return state.merge({
                posts : fromJS(action.posts),
                defpostid : fromJS(action.defpostid)
            })
        default:
            break;
    }
    return state;
}