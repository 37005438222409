import Cookie from "js-cookie"

/*
const snStorage={
    set:(name,value)=>{
        return sessionStorage.setItem(name,value);
    },
    get:(name)=>{
        return sessionStorage.getItem(name);
    },
    remove:(name)=>{
        return sessionStorage.removeItem(name);
    },
    clear:()=>{
        return sessionStorage.clear();
    }
}*/

const ckStorage={
    set:(name,value,expires=7,path='/')=>{
        return Cookie.set(name,value,{expires,path});
    },
    get:(name)=>{
        return Cookie.get(name)
    },
    remove:(name)=>{
        return Cookie.remove(name);
    }
}
export default ckStorage;