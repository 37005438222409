import axios from 'axios';
import { axiosConfig } from "./config";
import { message } from 'antd';

let Axios=axios;

//let loading=()=>{ message.loading('执行中...', 0) };
//let closed=()=>{ message.destroy() };
Axios.defaults.baseURL = axiosConfig.baseURL;
Axios.defaults.timeout = axiosConfig.timeout;

Axios.defaults.headers.common['Authorization'] = axiosConfig.Authorization;
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Axios.defaults.withCredentials = false;

Axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    //loading();
    return config;
}, function (error) {
    // 对请求错误做些什么
    //loading();
    return Promise.reject(error);
});

Axios.interceptors.response.use(function (res) {
    //closed();
    // 对响应数据做点什么
    const data=res.data;
    return data;
}, function (err) {
    //closed();
    // 对响应错误做点什么
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                err.message = '请求错误'
                break

            case 401:
                err.message = '未授权，请登录'
                break

            case 403:
                err.message = '拒绝访问'
                break

            case 404:
                err.message = `请求地址出错: ${err.response.config.url}`
                break

            case 408:
                err.message = '请求超时'
                break

            case 500:
                err.message = '服务器内部错误'
                break

            case 501:
                err.message = '服务未实现'
                break

            case 502:
                err.message = '网关错误'
                break

            case 503:
                err.message = '服务不可用'
                break

            case 504:
                err.message = '网关超时'
                break

            case 505:
                err.message = 'HTTP版本不受支持'
                break

            default:
        }
    }
    return Promise.reject(err);
});

Axios.ajax=(obj)=>{
    if(obj.method==="get"){
        obj.params=obj.data;
    }
    return axios(obj).then(res=>{
        return res;
    }).catch(err=>{
        message.error(String(err));
        return Promise.reject(err);
    });
}

export default Axios;