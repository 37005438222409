import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:[
            'name'
			,'status'
			,'description'
			// ,'time'
			// ,'num_multi'
			,'score'
			,'right_score'
			,'time'
        ]
        ,name:{type:'input',title:'试卷名称',name:'name',autoFocus:true,placeholder:"请输入试卷名称",rules:[{
            required: true,
            whitespace: true,
            message: '试卷名称不能为空'
        }]}
        ,description:{type:'text',title:'备注',rows:4,name:'description'}
        // ,thumb:{type:'upload',title:'封面图',name:'thumb',length:1,data:{path:'paper',size:6}}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启用'},
            {value:2,name:'禁用'}
		]}
        // ,num:{type:'input.number',title:'题目总数量',name:'num_single',min:0,placeholder:"默认:50"}
        // ,num_single:{type:'input.number',title:'单选题目数量',name:'num_single',min:0,placeholder:"默认:20"}
        // ,num_multi:{type:'input.number',title:'多选题目数量',name:'num_multi',min:0,placeholder:"默认:20"}
        ,score:{type:'input.number',title:'满分分数',name:'score',min:0,placeholder:"默认:100"}
        ,right_score:{type:'input.number',title:'及格分数',name:'right_score',min:0,placeholder:"默认:60"}
        ,time:{type:'input.number',title:'考试时长(分)',name:'time',min:0,placeholder:"默认:180"}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}