import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:[
            'name','urlpage','pagesize_start','pagesize_end','url_start','url_end'
            ,'title_rule'
            ,'title_html_rule'
            ,'author_rule'
            ,'author_html_rule'
            ,'comeform_rule'
            ,'comeform_html_rule'
            ,'time_rule'
            ,'time_html_rule'
            ,'content_rule'
            ,'content_html_rule'
            //,'down_attachment'
        ],
        name:{type:"input",title:'采集项目名',name:'name',style:{
            width:"250px"
        },
        line:true,autoFocus:true,rules:[{
            required: true,
            whitespace: true,
            message: '项目名不能为空'
        }]}
        ,urlpage:{type:"input",title:'网址配置',name:'urlpage',tip:"(如：http://www.baidu.com/(*).html,页码使用(*)做为通配符。)"}
        ,pagesize_start:{type:"input.number",min:1,title:'开始页码',name:'pagesize_start',style:{
            width:"50px"
        }}
        ,pagesize_end:{type:"input.number",min:1,title:'结束页码',name:'pagesize_end',line:true,style:{
            width:"50px"
        }}
        ,url_start:{type:"text",min:1,title:'获取网址开始html',name:'url_start',rows:4,tip:'(使用"[内容]"作为通配符)'}
        ,url_end:{type:"text",min:1,title:'获取网址结束html',name:'url_end',rows:4,line:true}
        ,title_rule:{type:"text",min:1,title:'标题规则',name:'title_rule',rows:4,tip:'(使用"[内容]"作为通配符)'}
        ,title_html_rule:{type:"text",min:1,title:'过滤规则',name:'title_html_rule',rows:4,line:true}
        ,author_rule:{type:"text",min:1,title:'作者规则',name:'author_rule',rows:4,tip:'(使用"[内容]"作为通配符)'}
        ,author_html_rule:{type:"text",min:1,title:'过滤规则',name:'author_html_rule',rows:4,line:true}
        ,comeform_rule:{type:"text",min:1,title:'来源规则',name:'comeform_rule',rows:4,tip:'(使用"[内容]"作为通配符)'}
        ,comeform_html_rule:{type:"text",min:1,title:'过滤规则',name:'comeform_html_rule',rows:4,line:true}
        ,time_rule:{type:"text",min:1,title:'时间规则',name:'time_rule',rows:4,tip:'(使用"[内容]"作为通配符)'}
        ,time_html_rule:{type:"text",min:1,title:'过滤规则',name:'time_html_rule',rows:4,line:true}
        ,content_rule:{type:"text",min:1,title:'内容规则',name:'content_rule',rows:4,tip:'(使用"[内容]"作为通配符)'}
        ,content_html_rule:{type:"text",min:1,title:'过滤规则',name:'content_html_rule',rows:4,line:true}
        ,down_attachment:{type:'radio',title:'下载图片',name:'down_attachment',options:[
            {value:1,name:'下载图片'},
            {value:0,name:'不下载'}
        ]}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}