import { Catch } from '../../utils'

export const isLogin=()=>{
    let access_token=Catch.get('access_token');
    return !!access_token
}
//时间戳转换成时间
export const tamptotime = (value,type='')=>{
    /**
     * @type 'm'
     */
    if(value<1){
        return '无';
    }else{
        var d=new Date(value*1000);
        return formatDate(d,type);
    }
}

export const getTimeNum=function(value,D='Y'){
    /**
     * D=Y|M|D
     */
    if(value<1){
        return '无';
    }else{
        var d=new Date(value*1000);
        return formatDate(d,D);
    }
}

//ip转换
export const strtoip=function( proper_address ) {
    proper_address = proper_address>>>0;
    var output = false;  // www.
    if ( !isNaN ( proper_address ) && ( proper_address >= 0 || proper_address <= 4294967295 ) ) {
        output = Math.floor (proper_address / Math.pow ( 256, 3 ) ) + '.' +
            Math.floor ( ( proper_address % Math.pow ( 256, 3 ) ) / Math.pow ( 256, 2 ) ) + '.' +
            Math.floor ( ( ( proper_address % Math.pow ( 256, 3 ) ) % Math.pow ( 256, 2 ) ) /
                Math.pow ( 256, 1 ) ) + '.' +
            Math.floor ( ( ( ( proper_address % Math.pow ( 256, 3 ) ) % Math.pow ( 256, 2 ) ) %
                Math.pow ( 256, 1 ) ) / Math.pow ( 256, 0 ) );
    }
    return output;
};

//过滤空数组
export const noempty = function(arr){
    let a=arr.filter((s)=>{
        if(s && s !== "" && s !== undefined){
            return s;
        }else{
            return false;
        }
    });
    return a;
}

//时间戳
const formatDate=function(now,d) {
    var year=now.getFullYear();
    var month=now.getMonth()+1;
    var date=now.getDate();
    var hour=now.getHours();
    var minute=now.getMinutes();
    var second=now.getSeconds();
    if(month<10){
        month='0'+month;
    }
    if(date<10){
        date='0'+date;
    }
    if(hour<10){
        hour='0'+hour;
    }
    if(minute<10){
        minute='0'+minute;
    }
    if(second<10){
        second='0'+second;
    }
    if(d==='m'){
        return year+"-"+month+"-"+date;
    }else if(d==='D'){
        return date;
    }else if(d==='Y'){
        return year;
    }else if(d==='M'){
        return month;
    }else{
        return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;
    }
}
