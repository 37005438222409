import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    btnloading:false,
    page:1,
    total:0,
    pageSize:Conf.page_Conf.pageSize,
    emptyText:'暂无数据',
    fielddata:{
        arr:['password']
        ,password:{type:'input.pass',title:'密码',name:'password',placeholder:"请输入密码"}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        case constants.BTNLOADING:
            return state.merge({
                btnloading : action.bool
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
        default:
    }
    return state;
}