import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    formData:{
        arr:[
			'pid',
			'name',
			'dirname',
			'type',
			'link',
            'thumb',
            'keyword',
            'description',
            'status',
            'sort',
        ]
		,pid:{type:'select.tree',title:'上级菜单',name:'pid',placeholder:"请选择上级菜单(默认一级菜单)",treeData:[]}
        ,name:{type:'input',title:'菜单名称',name:'name',autoFocus:true,placeholder:"请输入菜单名称",rules:[{
            required: true,
            message: '菜单名不能为空'
        }]}
        ,dirname:{type:'input',title:'别名',name:'dirname',placeholder:"请输入别名(英文)"}
        ,type:{type:'radio.button',title:'菜单类型',name:'type',options:[
            {value:1,name:'站内导航'},
            {value:2,name:'外部链接'}
        ]}
        ,link:{type:'select.tree',title:'链接',name:'link',placeholder:"请选择一个站内栏目",treeData:[]}
        ,thumb:{type:'upload',title:'缩略图',name:'thumb',length:1,data:{path:'menu',size:6}}
        ,status:{type:'radio',title:'是否显示',name:'status',options:[
            {value:1,name:'显示'},
            {value:0,name:'隐藏'}
        ]}
        ,sort:{type:'input.number',title:'排序',name:'sort',min:0,placeholder:"0"}
    },
    menutree:[],
    catetree:[],
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.MENUTREE:
            return state.merge({
                menutree : fromJS(action.menutree),
                catetree : fromJS(action.catetree)
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
        default:
    }
    return state;
}