import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    cates:[],
    defcateid:[],
    posts:[],
    defpostid:[],
    news:{},
    formLeft:{
		arr:[
            'cid','title'
			,'shorttitle'
            ,'color'
            ,'author','keywords','copyfrom','description','content','images'
			// ,'post_id'
		],
        cid:{type:'select.treeopen',title:'所属栏目',name:'cid',placeholder:"请选择栏目",treeData:[],rules:[{
            required: true,
            message: '栏目不能为空'
        }]}
        ,title:{type:'input',title:'标题',name:'title',autoFocus:true,placeholder:"请输入标题",rules:[{
            required: true,
            whitespace: true,
            message: '标题不能为空'
        }]}
        ,shorttitle:{type:'input',title:'副标题',name:'shorttitle',placeholder:"请输入副标题"}
        ,author:{type:'input',title:'责任编辑',name:'author',placeholder:"请输入责任编辑"
			// ,rules:[{
			// 	required: true,
			// 	whitespace: true,
			// 	message: '责任编辑不能为空'
			// }]
		}
        ,keywords:{type:'input',title:'关键字',name:'keywords'}
        ,copyfrom:{type:'input',title:'来源',name:'copyfrom'}
        ,description:{type:'text',title:'描述',name:'description',rows:4}
        ,content:{type:'text.ueditor',title:'内容',name:'content',config:{
                height:500
            },path:'news'
            ,rules:[{
                required: true,
                whitespace: true,
                message: '内容不能为空'
            }]
        }
        ,images:{type:'upload',title:'图片组',name:'images',length:20,data:{path:'news',size:6}}
		,post_id:{type:'checkbox.line',title:'推荐位',name:'post_id',options:[]}
    }
    ,formRight:{
        arr:[
			'thumb'
			,'color'
			,'jumpurl'
			,'fileurl'
			// ,'create_time'
			// ,'commentflag'
			// ,'building'
			// ,'location'
			// ,'material'
			// ,'surface'
			// ,'completiontime'
		]
        ,color:{type:'input.color',title:'背景颜色',name:'color'}
        ,thumb:{type:'upload',title:'缩略图',name:'thumb',length:1,data:{path:'news',size:6}}
        ,jumpurl:{type:'input.checked',title:'转向链接',name:'jumpurl'}
        ,commentflag:{type:'radio',title:'允许评论',name:'commentflag',options:[
            {value:1,name:'允许评论'},
            {value:0,name:'不允许评论'}
        ]}
		
        ,fileurl:{type:'upload',title:'附件',name:'fileurl',length:1,data:{
				path:'fileurl',
				size:20,
				type:'file',
				accept:"application/msexcel,application/msword,application/pdf,image/jpeg,image/png,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				listType:"picture-card"
			}
		}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        case constants.CATE:
            return state.merge({
                cates : fromJS(action.cates),
                defcateid : fromJS(action.defcateid)
            })
        case constants.POST:
            return state.merge({
                posts : fromJS(action.posts),
                defpostid : fromJS(action.defpostid)
            })
        default:
            break;
    }
    return state;
}