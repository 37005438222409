import * as constants from './constants';

export const setBtnLoading=(bool)=>{
    return {
        type:constants.BTNLOADING
        ,bool
    }
}

export const setTreeData=(treeData)=>{
    return {
        type:constants.TREEDATA
        ,treeData
    }
}

export const getUserInfo=(userInfo)=>{
    return {
        type:constants.GET_USERINFO
        ,userInfo
    }
}