import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:['name','logo','top','url','description','sort']
        ,name:{type:'input',title:'网站名称',name:'name',autoFocus:true,placeholder:"请输入网站名称",rules:[{
            required: true,
            whitespace: true,
            message: '网站名称不能为空'
        }]}
        ,logo:{type:'upload',title:'网站logo',name:'logo',length:1,data:{path:'links',size:6}}
        ,url:{type:'input',title:'网站网址',name:'url',autoFocus:true,placeholder:"请输入网站网址"
		// ,rules:[{
        //     required: true,
        //     whitespace: true,
        //     message: '网站网址不能为空'
        // }]
		}
        ,top:{type:'radio.button',title:'是否推荐显示',value:1,name:'top',options:[
            {name:"是",value:2},
            {name:"否",value:1}
        ]}
        ,description:{type:'text',title:'描述',rows:4,name:'description'}
        ,sort:{type:'input',title:'排序',name:'sort'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}