import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:['name','logo','description','sort']
        ,name:{type:'input',title:'国家名称',name:'name',autoFocus:true,placeholder:"请输入国家名称",rules:[{
            required: true,
            whitespace: true,
            message: '国家名称不能为空'
        }]}
        ,logo:{type:'upload',title:'国家图片',name:'logo',length:1,data:{path:'country',size:6}}
        ,description:{type:'text',title:'描述',rows:4,name:'description'}
        ,sort:{type:'input',title:'排序',name:'sort'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}