import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:['name',
		// 'name_en',,'description_en'
			"img",'color','start_time','end_time','description','url'
			//,'url_en'
			,'sort'
		]
        ,name:{type:'input',title:'广告标题',name:'name',autoFocus:true,placeholder:"请输入广告标题",rules:[{
            required: true,
            whitespace: true,
            message: '广告标题不能为空'
        }]}
		,name_en:{type:'input',title:'广告标题(英文)',name:'name_en',placeholder:"请输入广告英文标题"}
        ,img:{type:'input.hidden',title:'图片',name:'img'}
        ,color:{type:'input',title:'背景颜色',name:'color'}
        ,start_time:{type:'input.time',title:'上线时间',name:'start_time'}
        ,end_time:{type:'input.time',title:'下线时间',name:'end_time'}
        ,sort:{type:'input',title:'排序',name:'sort'}
        ,description:{type:'text',title:'图片提示',rows:8,name:'description'}
        ,description_en:{type:'text',title:'图片提示(英文)',rows:3,name:'description_en'}
        ,url:{type:'input',title:'链接地址',name:'url'}
        ,url_en:{type:'input',title:'链接地址(英文)',name:'url_en'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}