export default [
    //孕产管理
    {
        url: '/exam/organization',
        name: 'ExamOrganization',
        Com: import('../../exam/organization')
    }
    ,{
        url: '/exam/organization/list',
        name: 'ExamOrganizationList',
        Com: import('../../exam/organization/list')
    }
    //标签管理
    ,{
        url:'/exam/major',
        name:'ExamMajor',
        Com:import('../../exam/major')
    }
    //题库管理
    ,{
        url:'/exam/question',
        name:'ExamQuestion',
        Com:import('../../exam/questions')
    }
    
    ,{
        url:'/exam/paper',
        name:'ExamPaper',
        Com:import('../../exam/papers')
    },
    {
        url:'/exam/paper/add',
        name:'ExamPaperAdd',
        Com:import('../../exam/papers/add')
    },
    {
        url:'/exam/paper/edit/:id',
        name:'ExamPaperEdit',
        Com:import('../../exam/papers/edit')
    },
    {
        url:'/exam/user',
        name:'ExamUser',
        Com:import('../../exam/user')
    },
    {
        url:'/exam/userexam',
        name:'ExamUserexam',
        Com:import('../../exam/userexam')
    },
    {
        url:'/exam/examinfo',
        name:'ExamExamInfo',
        Com:import('../../exam/examinfo')
    },
    {
        url:'/exam/testinfo',
        name:'ExamTestInfo',
        Com:import('../../exam/testinfo')
    },
    {
        url:'/exam/setting',
        name:'ExamSetting',
        Com:import('../../exam/setting')
    },
]