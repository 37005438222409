import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState=fromJS({
    loading:true,
    btnloading:false,
    fielddata:{
        arr:['wendu','wendu_right','wendu_hot','wendu_warning','hot_status','open_status','one_time','space_time','smoke_time','smoke_right']
        ,wendu:{type:'input',title:'当前温度',name:'wendu'}
        ,wendu_right:{type:'input',title:'修正值',name:'wendu_right'}
        ,wendu_hot:{type:'input',title:'热风机设定温度',name:'wendu_hot'}
        ,wendu_warning:{type:'input',title:'温度高温报警值',name:'wendu_warning'}
        ,hot_status:{type:'radio',title:'热风机工作模式',name:'hot_status',options:[
            {value:1,name:'单机运行'},
            {value:2,name:'双机运行'}
        ]}
        ,open_status:{type:'radio',title:'电动风门开关',name:'open_status',options:[
            {value:1,name:'开'},
            {value:2,name:'关'}
        ]}
        ,one_time:{type:'input',title:'单次发烟时间',name:'one_time'}
        ,space_time:{type:'input',title:'发烟间隔时间',name:'space_time'}
        ,smoke_time:{type:'input',title:'发烟总时间',name:'smoke_time'}
        ,smoke_right:{type:'input',title:'烟雾浓度修正值',name:'smoke_right'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.BTNLOADING:
            return state.merge({
                btnloading : action.bool
            })
        default:
    }
    return state;
}