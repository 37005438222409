import axios from './axios';
import Catch from '../storage';
import { Message } from '../../components/Msgbox';
import { message } from 'antd';

/**
 * obj:
 *      method:post|get
 *      header:{}
 *      data:{}
 */

let onloading=()=>{ message.loading('执行中...', 0) };
let onclosed=()=>{ message.destroy() };
let ajax = function(url,data={},type='get',loading=true){
    let obj={
        url,
        method:type,
        data,
        headers:{
            'Authorization':Catch.get('access_token')
        }
    }
    let newObj = obj;
    if(loading){
        onloading()
    }
    return new Promise(function(resolve,reject){
        axios.ajax(newObj).then(res=>{
            onclosed();
            //过期通过刷新重新请求
            if(res.code===5){
                ajax.refresh().then(rs=>{
                    if(rs.code===0){
                        Catch.set('access_token',rs['data']['access_token'],1/12);
                        Catch.set('refresh_token',rs['data']['refresh_token'],7);
                        newObj.headers={
                            'Authorization':Catch.get('access_token')
                        }
                        
                        ajax(url,data,type,loading).then(r=>{
                            resolve(r)
                        }).catch(e=>{
                            reject(e)
                        })
                    }else{
                        Message('登录已经过期,请重新登录...');
                        Catch.remove('access_token');
                        Catch.remove('refresh_token');
                        setTimeout(()=>{
                            window.location.reload()
                        },1000);
                    }
                }).catch(er=>{
                    reject(er)
                })
            }else{
                Catch.set('access_token',Catch.get('access_token'),1/12);
                Catch.set('refresh_token',Catch.get('refresh_token'),7);
                resolve(res)
            }
        }).catch(err=>{
            onclosed();
            reject(err)
        })
    })
}

ajax.upload = function(url,data={},type='get',loading=true){
    let obj={
        url,
        method:type,
        data,
        headers:{
            'Authorization':Catch.get('access_token')
        }
        ,contentType: false
        ,processData: false
    }
    let newObj = obj;
    if(loading){
        onloading()
    }
    return new Promise(function(resolve,reject){
        axios.ajax(newObj).then(res=>{
            onclosed();
            //过期通过刷新重新请求
            if(res.code===5){
                ajax.refresh().then(rs=>{
                    if(rs.code===0){
                        Catch.set('access_token',rs['data']['access_token'],1/12);
                        Catch.set('refresh_token',rs['data']['refresh_token'],7);
                        newObj.headers={
                            'Authorization':Catch.get('access_token')
                        }
                        
                        ajax(url,data,type,loading).then(r=>{
                            resolve(r)
                        }).catch(e=>{
                            reject(e)
                        })
                    }else{
                        Message('登录已经过期,请重新登录...');
                        Catch.remove('access_token');
                        Catch.remove('refresh_token');
                        setTimeout(()=>{
                            window.location.reload()
                        },1000);
                    }
                }).catch(er=>{
                    reject(er)
                })
            }else{
                Catch.set('access_token',Catch.get('access_token'),1/12);
                Catch.set('refresh_token',Catch.get('refresh_token'),7);
                resolve(res)
            }
        }).catch(err=>{
            onclosed();
            reject(err)
        })
    })
}

/**
 * 不传递头部token请求
 */
ajax.all=function(url,data,type='get',loading=true){
    let newObj = {
        url,
        method:type,
        data
    };
    
    if(loading){
        onloading()
    }
    return new Promise(function(resolve,reject){
        axios.ajax(newObj).then(res=>{
            onclosed();
            resolve(res)
        }).catch(err=>{
            onclosed();
            reject(err)
        })
    })
}

/**
 * 不传递头部token请求
 */
ajax.refresh = function(){
    return new Promise(function(resolve,reject){
        let token = Catch.get('refresh_token')
        if(!!token){
            let obj={
                url:'refresh_token',
                method:'post',
                headers:{
                    'Authorization':token
                }
            }
            axios.ajax(obj).then(res=>{
                resolve(res)
            }).catch(err=>{
                reject(err)
            })
        }else{
            reject('_token不存在!失败')
        }
    })
}

export default ajax