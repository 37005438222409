import React, { PureComponent } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from "../../store";
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from "moment";
import 'moment/locale/zh-cn';

import { GlobalStyle } from './style'
import { Conf, Luck } from '../../utils'

// 路由  ---  // 循环路由
import LoadCom from "./loading";
import pages from "./config";

moment.locale('zh-cn');

const { menupage, hidepage } = pages;
class IRouter extends PureComponent{
    render(){
        return(
            <LocaleProvider locale={zhCN}>
            <BrowserRouter>
                <Provider store={ store }>
                    <GlobalStyle />
                    <Switch>
                        <Route path={Conf.Path+"/login"} component={LoadCom.Login} />
                        {
                            hidepage.map((item,index)=>{
                                return <Route path={Conf.Path+item.url} exact component={ props=>this.auth(LoadCom[item.name],props) } key={index} />
                            })
                        }
                        <Route path={Conf.Path} render={()=>(
                            <LoadCom.Admin>
                                <Switch>
                                    <Route path={Conf.Path+"/auth/role"} component={ props=>this.auth(LoadCom.Role,props) } />
                                    <Route path={Conf.Path+"/auth/rule"} component={ props=>this.auth(LoadCom.Rule,props) } />
                                    <Route path={Conf.Path+"/auth/manager"} component={ props=>this.auth(LoadCom.Manager,props) } />
                                    {/* 循环代码开始 */}
                                    {
                                        menupage.map((item,index)=>{
                                            return <Route path={item.url} exact component={ props=>this.auth(LoadCom[item.name],props) } key={index} />
                                        })
                                    }
                                    {/* 循环代码结束 */}
									<Redirect to={"/info"} />
                                    <Route component={ props=>this.auth(LoadCom.NoMatch,props) } />
                                </Switch>
                            </LoadCom.Admin>
                        )} />
                        <Redirect to={Conf.Path} />
                    </Switch>
                </Provider>
            </BrowserRouter>
            </LocaleProvider>
        );
    }

    //前端登录验证
    auth = (Layout, props)=>{
        if(!Luck.isLogin()){
            return <Redirect to={Conf.Path+"/login"} />
        }else{
            return <Layout {...props} />
        }
    }
}

export default IRouter;