import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.f_l{
    float:left;
}
.f_r{
    float:right;
}
.clearfix:after{content:"."; display:block; height:0; clear:both;visibility:hidden;}
*html .clearfix{ height:1%;}
*+html .clearfix{ height:1%;}
.block{
    display:block;
}
.none{
    display:none;
}

.text-center{
    text-align:center;
}
.text-left{
    text-align:left;
}
.text-right{
    text-align:right;
}

//自定义弹窗
.luck-modal.ant-modal-confirm .ant-modal-body{
    padding:15px 20px 10px 20px!important;
}
.ant-modal-confirm .ant-modal-confirm-btns{
    margin-top: 15px!important;
}
.black-btn{
    background: #001529 !important;
    border-color: #001529 !important;
    color:#fff!important;
}
.black-btn:active, .black-btn.active{
    color:#fff !important;
    border-color:#092136 !important;
}

h1{font-size:2em;}
h2{font-size:1.5em;}
h3{font-size:1.17em;}
h4{font-size:1em;}
h5{font-size:0.83em;}
h6{font-size:0.67em;}

.ant-divider-horizontal{
    margin: 0 0 15px 0 !important;
}
.ant-form-item{
    margin-bottom:15px!important;
}
`