import React from "react";
import Loadable from 'react-loadable';

import "./loading.less";
import pages from "./config";

const loadingComponent = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return <div id='loading'>
            <div className="dot"/>
            <div className="dot"/>
            <div className="dot"/>
            <div className="dot"/>
            <div className="dot"/>
        </div>
    }
    // Handle the error state
    else if (error) {
        console.log(error)
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>错误...</div>;
    }
    else {
        return null;
    }
};

let LoadCom={};

LoadCom.Admin = Loadable({
    loader: () => import('../../layouts'),
    loading: loadingComponent
});

LoadCom.Login= Loadable({
    loader: () => import('../login'),
    loading: loadingComponent
});

//没有匹配到
LoadCom.NoMatch= Loadable({
    loader: () => import('../nomatch'),
    loading: loadingComponent
});

/**
 * 权限管理 */
//规则管理
LoadCom.Rule= Loadable({
    loader: () => import('../default/auth/rule'),
    loading: loadingComponent
});
//角色管理
LoadCom.Role= Loadable({
    loader: () => import('../default/auth/role'),
    loading: loadingComponent
});
//管理员管理
LoadCom.Manager= Loadable({
    loader: () => import('../default/auth/manager'),
    loading: loadingComponent
});


/**
 * CMS管理
 */
/*LoadCom.CMSCategory= Loadable({
    loader: () => import('../cms/category'),
    loading: loadingComponent
});*/


//页面循环
const { menupage, hidepage } = pages;
for (let i = 0; i < menupage.length; i++) {
    LoadCom[menupage[i]['name']] = Loadable({
        loader: () => menupage[i]['Com'],
        loading: loadingComponent
    });
}
for (let i = 0; i < hidepage.length; i++) {
    LoadCom[hidepage[i]['name']] = Loadable({
        loader: () => hidepage[i]['Com'],
        loading: loadingComponent
    });
}

export default LoadCom;