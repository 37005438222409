import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:['name','img','url','description','sort']
        ,name:{type:'input',title:'关键词',name:'name',autoFocus:true,placeholder:"请输入关键词",rules:[{
            required: true,
            whitespace: true,
            message: '关键词不能为空'
        }]}
        ,img:{type:'upload',title:'缩略图',name:'img',length:1,data:{path:'keyword',size:1}}
        ,url:{type:'input',title:'网址',name:'url',placeholder:"跳转地址"
			,rules:[{
				required: true,
				whitespace: true,
				message: '网站网址不能为空'
			}]
		}
        ,description:{type:'text',title:'描述',rows:4,name:'description'}
        ,sort:{type:'input',title:'排序',name:'sort'}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}