import * as constants from './constants';
import { fromJS } from 'immutable';
import { Conf } from '../../../utils';

const defaultState=fromJS({
    list:[],
    loading:true,
    total:0,
    emptyText:'暂无数据',
    page:1,
    pageSize:Conf.page_Conf.pageSize,
    formData:{
        arr:[
			'major_id'
			,'title'
			,'content'
			,'answer'
			,'status'
			,'type'
			,'remark'
			//,'sort'
        ]
		,major_id:{
			type:'select',title:'所属试题类型',name:'major_id',
			options:[],
			value: '',
			rules:[{
				required: true,
				message: '所属试题类型必须选择'
			}]
		}
        ,title:{type:'input',title:'题干',name:'title',autoFocus:true,placeholder:"请输入题干",rules:[{
            required: true,
            whitespace: true,
            message: '题干不能为空'
		}]}
		,type:{type:'radio.hidden',title:'题库类型',name:'type',disabled:true,options:[
            {value:1,name:'单选题'},
            {value:2,name:'多选题'},
            {value:3,name:'问答题'},
            {value:9,name:'其他'}
		]}
		,content:{
			type:'TagInput',title:'选择项',name:'content',
			tags:[],
			inputVisible: false,
			value: '',
			focus:false,
			rules:[{
				required: true,
				message: '选择项不能为空'
			}]
		}
		,answer:{
			type:'select',title:'正确项',name:'answer',
			options:[],
			value: ''
		}
        ,remark:{type:'text',title:'答案解析',rows:4,name:'remark'}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启用'},
            {value:2,name:'禁用'}
		]}
        ,sort:{type:'input.number',title:'排序',name:'sort',min:0,placeholder:"0"}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.LIST:
            return state.merge({
                list : fromJS(action.list),
                total : action.total
            })
        case constants.LOADING:
            return state.merge({
                loading : action.loading
            })
        case constants.NOAUTH:
            return state.merge({
                emptyText : action.emptyText
            })
            
        case constants.PAGESIZE:
            return state.merge({
                pageSize : action.pageSize
            })
        case constants.PAGE:
            return state.merge({
                page : action.page
            })
        default:
            break;
    }
    return state;
}