import * as constants from './constants';

export const setLoading=(loading)=>{
    return {
        type:constants.LOADING
        ,loading
    }
};

export const setBtnLoading=(bool)=>{
    return {
        type:constants.BTNLOADING
        ,bool
    }
}
