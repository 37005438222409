import * as constants from './constants';
import { fromJS } from 'immutable';
import avatar from '../../../assets/images/face.jpg';

const defaultState=fromJS({
    collapsed:false,
    avatar,
	title:"后台管理系统",
	fielddata:{
        username:{type:'input',title:'用户名',name:'username',disabled:true,autoFocus:true,placeholder:"请输入用户名称",rules:[{
            required: true,
            message: '用户名不能为空'
        }]}
        ,password:{type:'input.pass',title:'密码',name:'password',placeholder:"请输入密码/默认空不修改"}
        ,nickname:{type:'input',title:'昵称',name:'nickname',placeholder:"请输入昵称名称"}
        ,head_img:{type:'upload',title:'头像',name:'head_img',length:1,data:{path:'avater',size:6}}
        ,status:{type:'radio',title:'状态',name:'status',options:[
            {value:1,name:'启动'},
            {value:0,name:'禁用'}
        ]}
        ,remark:{type:'text',title:'备注',name:'remark',rows:4}
    }
});
export default (state=defaultState,action)=>{
    switch (action.type) {
        case constants.ICON_TOGGLE:
            return state.set('collapsed',!action.value);
        case constants.HOME_TITLE:
            return state.set('title',action.value);
        default:
            break;
    }
    return state;
}